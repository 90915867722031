import React from "react";

export function TIRPaybackTable({
  vpl10anos,
  vpl30anos,
  tir,
  paybackAnual,
}: {
  vpl10anos: number;
  vpl30anos: number,
  tir: number;
  paybackAnual: number;
}) {
  return (
    <>
      <div className="economia-card">
        <h1>VPL (10 anos)</h1>
        <h2>
          {vpl10anos.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>

      <div className="economia-card">
        <h1>VPL (30 anos)</h1>
        <h2>
          {vpl30anos.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
      </div>

      {(vpl10anos > 0 || vpl30anos > 0) && (
        <>
          <div className="economia-card">
            <h1>TIR (30 anos)</h1>
            <h2>{String(tir).replace('.', ',')}%</h2>
          </div>
          <div className="economia-card">
            <h1>Payback</h1>
            <h2>{String(paybackAnual).replace('.', ',')} ano(s)</h2>
          </div>
        </>
      )}
    </>
  );
}
