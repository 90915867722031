import React from 'react'
import './style.css'
import BeProducer from '../../Assets/beProducer.jpg'
import TheMerx from '../../Assets/theMerx.jpg'
import Fade from 'react-reveal/Fade'

export default function About () {
  return (
    <div className='container-about'>
      <div className='item-about'>
        <Fade left>
          <img className='picImg' src={BeProducer} alt='Be Produce' />
        </Fade>
      </div>

      <div className='item-about'>
        <div className='textAbout'>
          <p className='titleAbout'>MERX</p>
          <span>
            A Merx é uma empresa comprometida em proporcionar as melhores
            soluções e experiências em energia elétrica, atuando de ponta a
            ponta, juntamente aos seus parceiros, para garantir soluções
            eficazes e seguras para uma melhor performance e resultados no
            quesito economia e eficiência energética. Sendo assim, é sinônimo de
            compromisso e excelência em transformar economia de energia em
            liberdade e investimento para crescer.
          </span>
          <span>
            Oferecemos todo o suporte técnico para que sua empresa. Aproveite as
            vantagens do ambiente de contratação livre, tanto como cliente final
            ou parceiro de negócios.
          </span>
        </div>
      </div>

      <div className='item-about'>
        <div className='textAbout'>
          <p className='titleAbout'>Seja produtor de energia elétrica</p>
          <span>
            Os principais atrativos da autoprodução são isenção de encargos
            setoriais, sem rocolhimento ICMS sobre energia produzida entre
            agentes de mesmo CNPJ de acordo com cada estado, diminuição da
            exposição e variações de preços, aplicação independente da
            localização geográfica e o direito de comercializar seus excedentes
            de energia com outros consumidores livres e até mesmo outros agentes
            de geração.
          </span>
        </div>
      </div>

      <div className='item-about'>
        <Fade right>
          <div style={{ minHeight: '595px' }} />
        </Fade>
      </div>
    </div>
  )
}
