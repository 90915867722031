import React from "react";
import downloadPdfIcon from "../../Assets/downloadPdfIcon.svg";
import disponivel from "../../Assets/disponivel.svg";
import emBreve from "../../Assets/emBreve.svg";
import "./style.css";

interface videoProps {
  numAula: number;
  titleAula: string;
  linkAula: string;
  nomePdf: string;
  disponibilizada: boolean;
  isCCEE: boolean;
}

export default function CardVideoUi({
  numAula,
  titleAula,
  linkAula,
  nomePdf,
  disponibilizada,
  isCCEE,
}: videoProps) {
  return (
    <a href={linkAula} className="videoUI" target="_blank">
      <div className="infosText">
        <h3 className={`${disponibilizada}EnableAula`}>Aula {numAula}</h3>
        <h2 className={`${disponibilizada}EnableAula`}>{titleAula}</h2>
      </div>
      <div className="arquivosAulaDisponibilidade">
        {disponibilizada && !isCCEE && (
          <a className="arquivo" href={nomePdf} download={titleAula}>
            <p>Arquivo de aula</p>
            <img
              src={downloadPdfIcon}
              alt="download aula"
              className="iconImageDownload"
            />
          </a>
        )}
        {isCCEE && <p className="aulaCCEE">*Aula feita pela CCEE</p>}
        {disponibilizada ? (
          <img src={disponivel} alt="imgAulaDisponivel" className="iconAula" />
        ) : (
          <img src={emBreve} alt="aulaEmBreve" className="iconAula" />
        )}
      </div>
    </a>
  );
}
