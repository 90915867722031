import React, { useRef } from "react";
import "./style.css";
import backArrow from "../../Assets/backArrow.svg";
import nextArrow from "../../Assets/nextArrow.svg";
import isActiveButton from "../../Assets/isActiveButton.svg";
import isNotActiveButton from "../../Assets/isNotActiveButton.svg";
import { useState, useEffect } from "react";
import CardVideoUi from "../CardVideoUi";
import aulaUm from "../../Assets/aula1.pdf";
export default function Main({ isCCEE }) {
  const listRef = useRef(null);
  const buttons = Array(8).fill(0);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const checkScrollPosition = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setIsStart(scrollLeft === 0);
      setIsEnd(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const trilhaMerx = [
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=JQhD1X0X1jw",
      nomePdf: { aulaUm },
      numAula: "1",
      titleAula: "Como encontrar o cliente",
      key: 1,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      nomePdf: "#",
      numAula: "2",
      titleAula: "Como se aproximar do cliente",
      key: 2,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      nomePdf: "#",
      numAula: "3",
      titleAula: "Como realizar a venda",
      key: 3,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      nomePdf: "#",
      numAula: "4",
      titleAula: "Diferença entre GD e APE",
      key: 4,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      nomePdf: "#",
      numAula: "5",
      titleAula: "Histórico da Autoprodução",
      key: 5,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      nomePdf: "#",
      numAula: "6",
      titleAula: `Etapas da APE `,
      key: 6,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      linkPdf: "#",
      numAula: "7",
      titleAula: "Impacto de grandes projetos no seu portfolio",
      key: 7,
    },
    {
      disponibilizada: false,
      linkAula: "#",
      linkPdf: "#",
      numAula: "8",
      titleAula: "Como realizar orçamentos de grandes usinas",
      key: 8,
    },
  ];
  const trilhaCCEE = [
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=sybqdCIsIsA",
      nomePdf: "#",
      numAula: "1",
      titleAula: "O caminho da energia elétrica",
      key: 1,
    },
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=ViFksZEd6VY",
      nomePdf: "#",
      numAula: "2",
      titleAula: "Diferenças entre o mundo físico e o mundo comercial",
      key: 2,
    },
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=L__8yEpD5Ok",
      nomePdf: "#",
      numAula: "3",
      titleAula: "Estrutura do setor elétrico e a CCEE",
      key: 3,
    },
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=2QpspF_wXmY",
      nomePdf: "#",
      numAula: "4",
      titleAula: "Comercialização de energia e seus participantes",
      key: 4,
    },
    {
      disponibilizada: true,
      linkAula: "https://www.youtube.com/watch?v=Hopd1Bw4gIY",
      nomePdf: "#",
      numAula: "5",
      titleAula: "Primeiros passos do consumidor no mercado livre",
      key: 5,
    },
  ];

  const newScrollS = (index) => {
    setActiveIndex(index);
    if (listRef.current) {
      if (listRef.current) {
        const scrollAmount = listRef.current?.scrollWidth ? 200 * index : 0;
        listRef.current.scrollTo({
          left: scrollAmount,
          behavior: "smooth",
        });
      }
      checkScrollPosition();
    }
  };

  const scrollToEnd = () => {
    const scrollAmount = listRef.current?.scrollWidth ? 200 : 0;
    if (listRef.current) {
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
    checkScrollPosition();
  };

  const scrollToStart = () => {
    if (listRef.current) {
      if (listRef.current) {
        const scrollAmount = listRef.current?.scrollWidth ? 200 : 0;
        listRef.current.scrollTo({
          left: listRef.current.scrollLeft - scrollAmount,
          behavior: "smooth",
        });
      }
      checkScrollPosition();
    }
  };

  useEffect(() => {
    checkScrollPosition();
    // Listener para verificar rolagem
    if (listRef.current) {
      listRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [listRef.current?.scrollWidth]);

  return (
    <div className="trilha">
      <h2>Trilha de conhecimento {isCCEE ? "CCEE" : "MERX"}</h2>
      {!isCCEE ? (
        <p>Comece por aqui</p>
      ) : (
        <p>
          Vídeos de introdução da CCEE para saber mais busque o portal de
          aprendizagem da CCEE
        </p>
      )}

      <div className="videos">
        <button onClick={scrollToStart}>
          <img src={backArrow} alt="Botão de retorno" />
        </button>
        <ul ref={listRef} className="videosList">
          {!isCCEE
            ? trilhaMerx.map((trilhaItem) => (
                <li>
                  <CardVideoUi
                    disponibilizada={trilhaItem.disponibilizada}
                    linkAula={trilhaItem.linkAula}
                    nomePdf={trilhaItem.nomePdf}
                    numAula={trilhaItem.numAula}
                    titleAula={trilhaItem.titleAula}
                    key={trilhaItem.key}
                    isCCEE={isCCEE}
                  />
                </li>
              ))
            : trilhaCCEE.map((trilhaItem) => (
                <li>
                  <CardVideoUi
                    disponibilizada={trilhaItem.disponibilizada}
                    linkAula={trilhaItem.linkAula}
                    nomePdf={trilhaItem.nomePdf}
                    numAula={trilhaItem.numAula}
                    titleAula={trilhaItem.titleAula}
                    key={trilhaItem.key}
                    isCCEE={isCCEE}
                  />
                </li>
              ))}
        </ul>
        <span className="buttonsMobileTrilha">
          {!isCCEE
            ? trilhaMerx.map((button, index) => (
                <img
                  src={
                    index === activeIndex ? isActiveButton : isNotActiveButton
                  }
                  className="activeButton"
                  onClick={() => newScrollS(index)}
                ></img>
              ))
            : trilhaCCEE.map((button, index) => (
                <img
                  src={
                    index === activeIndex ? isActiveButton : isNotActiveButton
                  }
                  className="activeButton"
                  onClick={() => newScrollS(index)}
                ></img>
              ))}
        </span>
        <button onClick={scrollToEnd}>
          <img src={nextArrow} alt="Botão de passar" />
        </button>
      </div>
    </div>
  );
}
