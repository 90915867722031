import React, { Component } from "react";
import "./style.css";
import BlackLogo from "../../Assets/blackLogo.png";
import { Form, Button } from "react-bootstrap";

import LoginModal from "../../Components/LoginModal/index";
import TermsModal from "../../Components/TermsModal/index";
import AccountCreatedModal from "../../Components/AccountCreatedModal/index";

import { withRouter } from "react-router-dom";

import api from "../../Services/apiSimulation";

class App extends Component {
  loginModalRef = (obj) => {
    this.showModal = obj && obj.handleShow;
  };

  onLoginClick = () => {
    this.showModal();
  };

  termsModalRef = (obj) => {
    this.showTermsModal = obj && obj.handleShow;
  };

  onTermsClick = () => {
    this.showTermsModal();
  };

  onAccountCreatedModalRef = (obj) => {
    this.showAccountCreatedModal = obj && obj.handleShow;
  };

  onAccountCreatedClick = () => {
    this.showAccountCreatedModal();
  };

  state = {
    nome: "",
    email: "",
    tel: "",
    cpf: "",
    companyCNPJ: "",
    password: "",
    Usertype: "cliente",
    PartnerID: "0",
    error: "",
    partnerList: [],
  };

  async componentDidMount() {
    const AllPartners = await api.get("/showPartners");

    this.setState({ partnerList: AllPartners.data });

    // console.log( this.state.partnerList )
  }

  handleSignUp = async (e) => {
    e.preventDefault();
    const {
      nome,
      email,
      tel,
      cpf,
      companyCNPJ,
      password,
      Usertype,
      PartnerID,
    } = this.state;

    if (!nome || !email || !password) {
      alert("Preencha todos os dados para se cadastrar");
    } else {
      try {
        await api.post("/sessions", {
          nome,
          email,
          tel,
          cpf,
          companyCNPJ,
          password,
          Usertype,
          PartnerID,
        });

        this.onAccountCreatedClick();

        //this.props.history.push("/");
      } catch (err) {
        console.log(err);
        alert("Ocorreu um erro ao registrar sua conta.");
      }
    }
  };

  render() {
    return (
      <div className="container-register42">
        <LoginModal ref={this.loginModalRef}></LoginModal>
        <TermsModal ref={this.termsModalRef}></TermsModal>
        <AccountCreatedModal
          ref={this.onAccountCreatedModalRef}
        ></AccountCreatedModal>
        <div className="register-box42">
          <Form onSubmit={this.handleSignUp}>
            <Form.Group controlId="registerGroupHeader">
              <Form.Label
                className="text-center loginTitle"
                style={{ width: "100%", marginTop: "30px" }}
              >
                <img alt="logo" src={BlackLogo} className="logo-login"></img>
              </Form.Label>
              <Form.Label
                className="text-center loginTitle"
                style={{ width: "100%", fontWeight: "bold" }}
              >
                Crie sua conta
              </Form.Label>
            </Form.Group>

            <Form.Group
              controlId="registerGroupName text-center"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">Nome Completo: </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Nome completo*"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ nome: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupEmail"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">E-mail: </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Email*"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupTel"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">Telefone: </Form.Label>
              <Form.Control
                required
                type="tel"
                placeholder="Telefone*"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ tel: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupCpf"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">CPF: </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="CPF (somente números)*"
                maxLength="11"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ cpf: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupCnpj"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label>CNPJ: </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="CNPJ*"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ companyCNPJ: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupOpt"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label>Tipo de usuário: </Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => this.setState({ Usertype: e.target.value })}
                required
                style={{ width: "300px", textAlign: "center" }}
              >
                <option value="cliente" className="text-center">
                  Cliente
                </option>
                <option value="parceiro" className="text-center">
                  Parceiro
                </option>
              </Form.Control>
            </Form.Group>

            {this.state.Usertype === "cliente" && (
              <Form.Group
                controlId="registerGroupOpt"
                style={{
                  textALign: "center",
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "50%",
                  transform: "translateX(-50%",
                }}
              >
                <Form.Label>Qual seu parceiro?</Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => this.setState({ PartnerID: e.target.value })}
                  required
                  style={{ width: "300px", textAlign: "center" }}
                >
                  <option value="0" className="text-center">
                    Não tenho nenhum
                  </option>

                  {this.state.partnerList.map((partner) => {
                    return (
                      <option
                        value={partner._id}
                        key={partner._id}
                        className="text-center"
                      >
                        {partner.nome}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            )}

            <Form.Group
              controlId="registerGroupPass"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">Senha: </Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Senha*"
                style={{ width: "300px" }}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupPassConfirm"
              style={{
                textALign: "center",
                display: "flex",
                flexDirection: "column",
                marginLeft: "50%",
                transform: "translateX(-50%",
              }}
            >
              <Form.Label className="">Confirme sua senha: </Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Confirmar Senha*"
                style={{ width: "300px" }}
              />
            </Form.Group>

            <Form.Group
              controlId="registerGroupcCheckbox"
              className="check-box-cont"
            >
              <Form.Check
                required
                type="checkbox"
                label="Eu aceito os termos e condições"
              />
            </Form.Group>

            <Button
              className="btn-team register-button register-cell"
              type="submit"
            >
              CRIAR CONTA
            </Button>

            <Form.Group controlId="formGroupFooter">
              <Form.Label className="text-center" style={{ width: "100%" }}>
                <p className="loginText" style={{ marginTop: "40px" }}>
                  Já tem conta?
                  <a
                    onClick={this.onLoginClick}
                    href="#?"
                    style={{
                      display: "inline",
                      color: "#0067CC",
                      marginLeft: "5px",
                    }}
                  >
                    Faça login
                  </a>
                </p>
              </Form.Label>

              <Form.Label className="text-center" style={{ width: "100%" }}>
                <a
                  href="/"
                  style={{
                    marginTop: "20px",
                    color: "rgba(64, 64, 64, 0.8)",
                    fontSize: "12px",
                    textDecorationLine: "underline",
                  }}
                >
                  Ir para home
                </a>
              </Form.Label>

              <Form.Label className="text-center" style={{ width: "100%" }}>
                <a
                  href="#?"
                  onClick={this.onTermsClick}
                  style={{
                    marginTop: "20px",
                    color: "rgba(64, 64, 64, 0.8)",
                    fontSize: "12px",
                    textDecorationLine: "underline",
                  }}
                >
                  Termos e condições
                </a>
              </Form.Label>

              <Form.Label className="text-center" style={{ width: "100%" }}>
                <p
                  style={{
                    marginTop: "20px",
                    fontSize: "12px",
                    color: "rgba(151, 151, 151, 0.8)",
                  }}
                >
                  MERX's Termos de serviço & Políticas de privacidade
                </p>
              </Form.Label>
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
