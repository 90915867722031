export function formatCNPJ(input: string | undefined) {
  if (input) {
    return input.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  } else {
    return "";
  }
}

export const mesesExt = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

export const shortMesesExt = [
  "jan",
  "fev",
  "mar",
  "abr",
  "mai",
  "jun",
  "jul",
  "ago",
  "set",
  "out",
  "nov",
  "dez",
];

export function parseRegiao(input: string) {
  const inputUC = input.toUpperCase();

  //Quando faz pesquisa no submercado do CCEE centro-oeste sudeste é o mesmo sub-mercado
  if (inputUC === "CENTRO-OESTE" || inputUC === "CENTROOESTE") {
    return "SUDESTE";
  } else if (inputUC === "NE" || inputUC === "NORDESTE") {
    return "Nordeste";
  } else if (inputUC === "NORTE") {
    return "Norte";
  } else if (inputUC === "SUL") {
    return "Sul";
  } else if (inputUC === "SUDESTE" || inputUC === "SE") {
    return "Sudeste/Centro-Oeste";
  } else {
    return "error";
  }
}

export function capitalize(input: string) {
  const inputUC = input.toLowerCase();
  const firstLetter = inputUC.charAt(0).toUpperCase();
  const wordTail = inputUC.slice(1);
  return (firstLetter + wordTail).replace("-", " ").replace("_", " ");
}

export function convertDate(date: string) {
  return `${new Date(date).toLocaleDateString("pt-br").split(",")[0]}`;
}
