import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";

import logo from "../../Assets/fortlev_solar_vetor_2.svg";

class App extends Component {
  constructor(props) {
    super();
    var colorBack = "#00386d";
    var fontColor = "#FFFFFF";
    var setColor;

    this.state = {
      hide: true,
      colorBack,
      fontColor,
      changeColor: setColor,
      name: "Merx",
      Usertype: "cliente",
    };
  }

  changeHide = (value) => {
    this.setState({ hide: value });
  };

  async componentDidMount() {
    const handleScroll = () => {
      const headerHeight = document
        .getElementById("headerId")
        .getBoundingClientRect().bottom;

      // Se a altura do Header for menor que 0, ou seja, se ele não estiver visível,
      // remova a classe 'hidden' da Navbar para torná-la visível.
      this.changeHide(headerHeight > 200);
    };

    window.addEventListener("scroll", handleScroll);

    // Limpeza da função
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }

  render() {
    const divStyle = {
      backgroundColor: this.state.colorBack,
      color: this.state.fontColor,
      borderColor: this.state.fontColor,
    };

    const scrollToHeader = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const scrollToMercadoLivre = () => {
      const element = document.getElementById("mercadoLivreId");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const scrollToAutoprodutor = () => {
      const element = document.getElementById("autoprodutorId");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const scrollToSimulador = () => {
      const element = document.getElementById("simuladorId");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const scrollToCasosReais = () => {
      const element = document.getElementById("casosReaisId");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const scrollToEmail = () => {
      const element = document.getElementById("emailId");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    const scrollToEnd = () => {
      window.scrollTo({
        top: 20000,
        behavior: "smooth",
      });
    };

    return (
      <div>
        <Navbar
          collapseOnSelect
          id="navbarId"
          className={`${this.state.hide ? "hidden" : ""}`}
          expand="lg"
          style={divStyle}
          variant="light"
        >
          <Navbar.Brand>
            <img
              className={`logoBrand ${this.state.hide ? "hidden" : ""}`}
              src={logo}
              alt="Logo"
              onClick={() => scrollToHeader()}
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-navItems">
              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                onClick={() => scrollToSimulador()}
              >
                Simule sua economia
              </Nav.Link>

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                onClick={() => scrollToCasosReais()}
              >
                Casos reais
              </Nav.Link>

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                onClick={() => scrollToMercadoLivre()}
              >
                Mercado Livre de Energia
              </Nav.Link>

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                onClick={() => scrollToAutoprodutor()}
              >
                Autoprodução de Energia
              </Nav.Link>

              <Nav.Link
                id="navItem-color"
                style={divStyle}
                className="hvr-underline-from-left"
                onClick={() => scrollToEmail()}
              >
                Contato
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
export default App;
