import React from "react";
import "./styles.css";
import NavBar from "../../Components/Navbar/index";
import Header from "../../Components/Header/index";
import HeaderFooter from "../../Components/HeaderFooter/index";
import Compare from "../../Components/Compare/index";
import About from "../../Components/About/index";
import Revolution from "../../Components/Revolution/index";
import Team from "../../Components/Team/index";
import Footer from "../../Components/Footer/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function Main() {
  return (
    <div className="master-container">
      <NavBar changeColor="true" />
      <Header />
      <HeaderFooter />
      <Compare />
      <About />
      <Revolution />
      <Team />
      <Footer />
      <a href="https://wa.me/5581982136133" className="float">
        <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
      </a>
    </div>
  );
}
