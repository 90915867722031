import React from "react";
import "./style.css";
import Logo from "../../Assets/blackLogo.png";
import Zap from "../../Assets/zap.png";
import Fones from "../../Assets/telfot.png";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div style={{ width: "25%", display: "flex", justifyContent: "center" }}>
        <img className="logo-footer" src={Logo} alt="f2" />
      </div>
      <div style={{ width: "75%" }}>
        <div className="row-footer">
          <div className="column-footer">
            <p className="footer-text">
              Empresarial Cícero Dias - R. Padre Carapuceiro, 858 - Boa Viagem,
              Recife - PE
            </p>
          </div>
          <div className="column-footer">
            <p className="footer-text">
              <img src={Fones} alt="zap" className="iconFootex" />
              +55 81 3204-8980
            </p>
          </div>
          <div className="column-footer">
            <a
              className="footer-text"
              target="blank"
              href="https://wa.me/5581982136133"
            >
              <img src={Zap} alt="zap" className="iconFootex" />
              +55 81 98213-6133
            </a>
          </div>
        </div>
        <div className="row-footer">
          <div
            className="column-footer"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <p className="under-footer">
              {`${year} | A MERX é uma plataforma de tecnologia que descomplica o mercado de energia de forma simples, sem carência, sem franquia e sem burocracia.`}
              <br />
              <br />
            </p>
            {/* <div>
              <a style={{ fontSize: 11, color: "#222" }} href="/policy-privacy">
                Políticas de privacidade |
              </a>{" "}
              <a style={{ fontSize: 11, color: "#222" }} href="/terms-of-use">
                Termos de uso
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
