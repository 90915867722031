import React from "react";
import { Modal } from "react-bootstrap";
import BlackLogo from "../../Assets/blackLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

import api from "../../Services/apiSimulation";

import { login } from "../../Services/auth";

class LoginModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      email: "",
      password: "",
      error: "",
    };
  }

  handleShow() {
    // console.log(this.state)
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/auth/login", { email, password });

        login(response.data.accessToken);

        window.location.reload();
      } catch (err) {
        this.setState({
          error: "Credenciais incorretas ou pendentes de validação.",
        });
      }
    }
  };

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="custom-dialog"
          centered
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Modal.Body className="modal-bodyCheck">
            <div className="container-modalFinish">
              <img alt="logo" src={BlackLogo} className="logo-login"></img>

              <p
                className="text-center loginTitleCheck"
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  marginTop: "30px",
                  marginBottom: "35px",
                }}
              >
                Seus dados foram armazenados com sucesso em nosso sistema!
              </p>

              <div className="checkEntrar">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="okay-icon"
                  onClick={this.handleClose}
                />
                <p
                  className="text-center loginTitleChecksub"
                  style={{
                    width: "100%",
                    fontWeight: "bold",
                    marginTop: "30px",
                    marginBottom: "35px",
                  }}
                >
                  Entraremos em contato para confirmar o cadastro.
                </p>
                <a className="buttonToHome" href="/">
                  Voltar para Home
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default LoginModal;
