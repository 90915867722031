import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { getToken } from "../../Services/auth";
import api from "../../Services/api";
import "./style.css";

function PartnerEditInfo({ partnerInfo, show, handleClose }) {
  const [name, setName] = useState(partnerInfo.name);
  const [email, setEmail] = useState(partnerInfo.email);
  const [previousPassword, setPreviousPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [sucessMessage, setSucessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [messageEmailName, setMessageEmailName] = useState("");

  const passwordValidation = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length <= 9) {
      setPasswordError("A senha precisa conter no mínimo 10 caracteres");
    }
  };

  const clearStates = () => {
    setPassword("");
    setConfirmPassword("");
    setEmail("");
    setSucessMessage("");
    setPreviousPassword("");
    setDisabled(false);
  };

  const saveNameEmail = async () => {
    setDisabled(true);
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const data = {
      name: name ? name : undefined,
      email: email ? email : undefined,
    };

    await api
      .patch(`/users/${partnerInfo.id}`, data, {
        headers,
      })
      .then((response) => {
        setMessageEmailName("Nome e e-mail alterados com sucesso");
        setErrorMessage("");
      })
      .catch((response) => {
        setSucessMessage("");
      });
  };

  const savePassword = async () => {
    setDisabled(true);
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    const data = {
      previousPassword: previousPassword ? previousPassword : undefined,
      password: password ? password : undefined,
    };

    try {
      await api
        .patch(`/users/${partnerInfo.id}`, data, {
          headers,
        })
        .then((response) => {
          setSucessMessage("Senha alterada com sucesso");
          setErrorMessage("");
        })
        .catch((response) => {
          setSucessMessage("");
          setErrorMessage(
            response.response?.data.message ?? "Erro ao tentar alterar a senha"
          );
        });
    } catch (e) {
      window.alert(
        "Erro ao tentar salvar sua senha, por favor, verifique se os campos estão corretos!"
      );
      handleClose();
      clearStates();
    }
  };

  return (
    <>
      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Edite as suas informações</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "8px" }}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nome de exibição</Form.Label>
              <Form.Control
                type="text"
                placeholder={partnerInfo.name}
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                placeholder={partnerInfo.email}
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={saveNameEmail}
                className="saveInfos"
              >
                Atualizar informações
              </Button>
              <div className="infosFooterPartner">
                {messageEmailName && (
                  <div
                    id="validationServer03Feedback"
                    className={`valid-feedback ${
                      messageEmailName.length > 0
                    }EnableError
                  `}
                  >
                    Informações alteradas com sucesso!
                  </div>
                )}
              </div>
            </Modal.Footer>
          </Form>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Senha antiga</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={previousPassword}
                onChange={(e) => setPreviousPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={password}
                onChange={(e) => passwordValidation(e)}
              />
              <div
                id="validationServer03Feedback"
                className={`invalid-feedback ${
                  password.length <= 9 && password.length > 0
                } EnableError`}
              >
                {passwordError}
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Confirme sua nova senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="••••••"
                autoFocus
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                id="validationServer03Feedback"
                className={`invalid-feedback ${
                  password !== confirmPassword && confirmPassword.length > 0
                }EnableError`}
              >
                A confirmação tem que ser igual a nova senha
              </div>
            </Form.Group>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={savePassword}
                className="saveInfos"
              >
                Atualizar senha
              </Button>
              <div className="infosFooterPartner">
                {sucessMessage && (
                  <div
                    id="validationServer03Feedback"
                    className={`valid-feedback ${
                      sucessMessage.length > 0}EnableError
                    }`}
                  >
                    Informações alteradas com sucesso!
                  </div>
                )}
                {errorMessage && (
                  <div
                    id="validationServer03Feedback"
                    className="invalid-feedback trueEnableError"
                  >
                    {errorMessage}
                  </div>
                )}
              </div>
            </Modal.Footer>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default PartnerEditInfo;
