import React, { useRef, useState, useEffect } from "react";
import { convertDate } from "../../utils/utilityFunctions";
import cadAtivoOk from "../../Assets/cadAtivoOk.png";
import cadInativoOk from "../../Assets/inativoOk.png";
import "./style.css";
import migCompletaOk from "../../Assets/migCompletaOk.svg";
import migIncompleta from "../../Assets/migIncompleta.svg";
import iconNext from "../../Assets/nextArrow.svg";
import iconBack from "../../Assets/backArrow.svg";
import isActiveButton from "../../Assets/isActiveButton.svg";
import isNotActiveButton from "../../Assets/isNotActiveButton.svg";
import { useHistory } from "react-router-dom";
interface Check {
  aprovacaoCheck: true;
  cceeCheck: true;
  distribuidoraCheck: true;
  energiaCheck: true;
  financeiroCheck: true;
  medicaoCheck: true;
}

interface Affiliate {
  id?: string;
  name?: string;
  check?: Check;
  logo?: string;
  cnpj: string;
  start_acl?: string;
}

interface MainProps {
  Affiliates: Affiliate[];
  economiaTotal: number;
  qtdAffiliates: number;
}

export default function Main({
  Affiliates,
  economiaTotal,
  qtdAffiliates,
}: MainProps) {
  const history = useHistory();
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [scrollPosition, setScrollPositionn] = useState(0);

  const checkScrollPosition = () => {
    if (listRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listRef.current;
      setIsStart(scrollLeft === 0);
      setIsEnd(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const handleGoToAffiliate = (affiliateId: string) => {
    localStorage.setItem("affiliateId", affiliateId);
    history.push("/empresa");
  };
  function formatCurrency(n: number) {
    if (n) {
      return new Intl.NumberFormat("pt-BR", {}).format(n);
    }
  }
  function formatCNPJ(cnpj: String) {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
  const [activeIndex, setActiveIndex] = useState<number>();

  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        setScrollPositionn(listRef.current.scrollLeft);
        const dividir = 2200 / qtdAffiliates;
        const divisaoScroll = Math.floor(listRef.current.scrollLeft / dividir);
        setActiveIndex(divisaoScroll);
      }
    };

    const listElement = listRef.current;

    if (listElement) {
      listElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listElement) {
        listElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [qtdAffiliates]);

  const handleNewScroll = (index: number) => {
    setActiveIndex(index);
    if (listRef.current) {
      const scrollAmount = index * 200; // Baseia o scroll na largura visível e na quantidade de parceiros
      listRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
    checkScrollPosition();
  };

  const handleNext = (qtdScroll: number) => {
    if (listRef.current) {
      const scrollAmount = qtdScroll; // Baseia o scroll na largura visível e na quantidade de parceiros
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft + scrollAmount,
        behavior: "smooth",
      });
    }
    checkScrollPosition();
  };

  const handlePreview = (qtdScroll: number) => {
    if (listRef.current) {
      const scrollAmount = qtdScroll; // Mesmo cálculo para o scroll para trás
      listRef.current.scrollTo({
        left: listRef.current.scrollLeft - scrollAmount,
        behavior: "smooth",
      });
    }
    checkScrollPosition();
  };

  useEffect(() => {
    checkScrollPosition();
    // Listener para verificar rolagem
    if (listRef.current) {
      listRef.current.addEventListener("scroll", checkScrollPosition);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [listRef.current?.scrollWidth]);

  //
  return (
    <div className="allContent">
      <button
        className={`buttonNext ${isStart}Enable f-w`}
        onClick={() => handlePreview(400)}
        disabled={isStart}
      >
        <img src={iconBack} alt="iconNext" />
      </button>
      <div className="indicados" ref={listRef}>
        {Affiliates &&
          Affiliates.map((Affiliate: any, index: number) => (
            <div className="infosIndicado">
              <div key={index} className="indicado">
                <h2>{Affiliate.initials}</h2>
                {Affiliate.check.aprovacaoCheck ? (
                  <div className="migrationStatusCliente">
                    <img src={migCompletaOk} alt="ok" width="15" height="15" />
                    <h3>Migração completa</h3>
                  </div>
                ) : (
                  <div className="migrationStatusCliente">
                    <img src={migIncompleta} alt="ok" width="15" height="15" />
                    <h3>Migração em progresso</h3>
                  </div>
                )}

                <h3 className="cnpj">{formatCNPJ(Affiliate.cnpj)}</h3>
                {Affiliate.check.aprovacaoCheck && (
                  <p className="migracaoDate">
                    O cliente migrou em {convertDate(Affiliate.start_acl)}
                  </p>
                )}
                <button
                  className={`acessClient ${Affiliate.check.aprovacaoCheck}Check `}
                  onClick={() => handleGoToAffiliate(Affiliate.id)}
                >
                  Acessar área do cliente
                </button>
              </div>

              <img
                src={Affiliate.logo}
                alt="logo Empresa"
                className="empresaLogo"
              ></img>
            </div>
          ))}
      </div>
      <button className={`buttonBack ${isEnd}Enable f-w`} disabled={isEnd}>
        <img src={iconNext} alt="iconNext" onClick={() => handleNext(400)} />
      </button>
      <div className="buttonsMobile">
        {Affiliates &&
          Affiliates.map((Affiliate, index) =>
            activeIndex === index ? (
              <img
                src={isActiveButton}
                alt="botaoAtivo"
                onClick={() => handleNewScroll(index)}
              />
            ) : (
              <img
                src={isNotActiveButton}
                alt="botaoNaoAtivo"
                onClick={() => handleNewScroll(index)}
              />
            )
          )}
      </div>
    </div>
  );
}
