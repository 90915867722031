import api from "./api";

export const TOKEN_KEY = "@merx-Token";

export const isAuthenticated = () => {
  if (
    localStorage.getItem(TOKEN_KEY) !== null &&
    localStorage.getItem(TOKEN_KEY) !== "undefined"
  ) {
    return true;
  } else logout();
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getRole = () => localStorage.getItem("role");
export const getUserId = () => localStorage.getItem("id");

export const updateAccessToken = async (refreshToken, id) => {
  try {
    const response = await api.post("/auth/refresh-token", {
      refreshToken: refreshToken,
      id: id,
    });

    const newAccessToken = response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;

    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("refreshToken");

    localStorage.setItem(TOKEN_KEY, newAccessToken);
    localStorage.setItem("refreshToken", newRefreshToken);

    return response;
  } catch (error) {
    console.log("Error updating access token:", error);
    logout();
  }
};

export const login = (token, userId, refreshToken, role) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem("refreshToken", refreshToken);
  localStorage.setItem("id", userId);
  localStorage.setItem("role", role);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("affiliateId");
  localStorage.removeItem("id");
  localStorage.removeItem("role");
};
