import React, { useEffect, useState } from "react";
import { getToken, getUserId } from "../../Services/auth";
import { Helmet } from "react-helmet";
import api from "../../Services/apiSimulation";

import FormSimulacao from "../../Components/FortlevSolar/index";
import Navbar from "../../Components/FortlevSolar/Navbar";
import Header from "../../Components/FortlevSolar/Header";
import CasosReais from "../../Components/FortlevSolar/CasosReais";
import Main from "../../Components/FortlevSolar/VideoLivre";
import Autoprodutor from "../../Components/FortlevSolar/Autoprodutor";
import HeaderDefault from "../../Components/FortlevSolar/MercadoLivre";
import FormsSimulador from "../../Components/FortlevSolar/Forms";
import Footer from "../../Components/FortlevSolar/Footer";
import fortlevsolarlogo from "../../Assets/fortlev-solar-logo-256.png";

export default function FortlevSolar() {
  const [authenticated, setAuthenticated] = useState(false);

  const pageUrl = window.location.href; // URL atual da página
  const pageTitle = "Fortlev Solar";

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']");

    if (link) {
      link.href = "/fortlev-solar.ico";
    }

    return () => {
      if (link) {
        link.href = "/favicon.ico";
      }
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const my_token = getToken();
      const id = getUserId();

      const getTokenfortlevSolar = localStorage.getItem("FORTLEV_SOLAR_TOKEN");

      try {
        var response = await api.get(`users/${id}`, {
          headers: { authorization: "Bearer " + my_token },
        });
      } catch (err) {
        //console.log(err);
        //alert("Erro ao pegar os dados do usuario");
      }

      setAuthenticated(
        !!response?.data?.email || getTokenfortlevSolar === "true"
          ? true
          : false
      );
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:site_name" content="Fortlev Solar" />
        <meta
          property="og:title"
          content="Fortlev Solar - Autoprodução de energia"
        />
        <meta
          property="og:description"
          content="Venha se tornar Autoprodutor e tenha grandes descontos na sua fatura de energia."
        />
        <meta property="og:image" itemprop="image" content={fortlevsolarlogo} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={pageUrl} />
      </Helmet>

      <Navbar />

      <Header />

      {authenticated ? <FormSimulacao /> : <FormsSimulador />}

      <CasosReais />

      <HeaderDefault screen="mercado-livre" />

      <Main />

      <HeaderDefault screen="autoproducao" />

      <Autoprodutor />

      <Footer />
    </>
  );
}
