import React from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import BlackLogo from "../../Assets/blackLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./style.css";

import api from "../../Services/apiSimulation";

import { login } from "../../Services/auth";

class LoginModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      email: "",
      password: "",
      error: "",
      waiting: false,
    };
  }

  handleShow() {
    // console.log(this.state)
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ waiting: true });
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      await api
        .post("/auth/login", {
          email: email.toLowerCase(),
          password,
        })
        .then((response) => {
          login(
            response.data.accessToken,
            response.data.id,
            response.data.refreshToken
          );
          window.location.reload();
        })
        .catch((response) => {
          this.setState({ waiting: false });
          if (
            response.response?.data.message ===
            "O acesso da sua conta está expirado!"
          )
            this.setState({
              error: "O acesso da sua conta está expirado!",
            });
          else
            this.setState({
              error: "Credenciais incorretas ou pendentes de validação.",
            });
        });
    }
  };

  render() {
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          dialogClassName="custom-dialog"
          centered
          style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        >
          <Modal.Body className="modal-body">
            <div className="container-modal">
              <FontAwesomeIcon
                icon={faTimes}
                className="close-icon"
                onClick={this.handleClose}
              />
              <Form onSubmit={this.handleSignIn}>
                <Form.Group controlId="formGroupHeader">
                  <Form.Label
                    className="text-center loginTitle"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <img
                      alt="logo"
                      src={BlackLogo}
                      className="logo-login"
                    ></img>
                  </Form.Label>
                  <Form.Label
                    className="text-center loginTitle"
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      marginTop: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    Entrar
                  </Form.Label>
                </Form.Group>

                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    className="text-center center-input"
                    type="email"
                    placeholder="Seu email"
                    style={{ width: "268px" }}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </Form.Group>

                <Form.Group controlId="formGroupPassword">
                  <Form.Control
                    className="text-center center-input"
                    type="password"
                    placeholder="Sua senha"
                    style={{ width: "268px" }}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </Form.Group>

                <button
                  className="fb-button color-change buffer-space-login-2"
                  type="submit"
                >
                  Entrar
                </button>

                {this.state.waiting && (
                  <div className="externalSpinnerLogin">
                    <Spinner
                      animation="border"
                      variant="secondary"
                      className="spinnerLogin"
                    />
                  </div>
                )}

                <div
                  style={{
                    marginTop: "8px",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  {this.state.error && <p>{this.state.error}</p>}
                </div>

                <Form.Group
                  controlId="formGroupFooter"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  {/* <Form.Label className="text-center" style={{ width: "100%" }}>
                    <p className="loginText">
                      Não tem conta?
                      <a
                        href="register"
                        style={{
                          display: "inline",
                          color: "#0067CC",
                          marginLeft: "5px",
                        }}
                      >
                        Crie sua conta
                      </a>
                    </p>
                  </Form.Label> */}

                  {/* <Form.Label
                    className="text-center"
                    style={{ width: "100%", marginTop: "15px" }}
                  >
                    <a
                      href="https://google.com"
                      style={{
                        color: "rgba(64, 64, 64, 0.8)",
                        fontSize: "12px",
                        textDecorationLine: "underline",
                      }}
                    >
                      Esqueceu sua senha?
                    </a>
                  </Form.Label> */}

                  <Form.Label
                    className="text-center"
                    style={{ width: "100%", marginTop: "16px" }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        fontSize: "12px",
                        color: "rgba(151, 151, 151, 0.8)",
                      }}
                    >
                      MERX's Termos de serviço & Políticas de privacidade
                    </p>
                  </Form.Label>
                </Form.Group>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LoginModal;
